.header {
  position: fixed;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  background-color: white;
  color: #3b3a36;
  text-align: center;
  margin-top: 5px;
  padding: 0px;
  .img {
    height: 50px;
    width: 116px;
  }
}
