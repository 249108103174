/* HomePage.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.header {
  background-color: #333;
  color: #fff;
  padding: 1rem;
}

.navbar {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-list li {
  display: inline;
  margin-right: 1rem;
}

.nav-list a {
  color: #fff;
  text-decoration: none;
}

main {
  padding: 2rem;
}

.hero {
  background-color: #f0f0f0;
  padding: 3rem;
  text-align: center;
  height: 100vh;
}

.hero-content {
  margin-top: calc(40vh - 50px);
  text-align: center;
  width: 100%;
}

h1 {
  font-size: 3rem;
  color: #3b3a36;
}

.btn {
  display: inline-block;
  background-color: #3b3a36;
  color: #fff;
  padding: 1rem 2rem;
  text-decoration: none;
  margin-top: 1rem;
}
