.footer {
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  text-align: center;
  border-top: 1px solid #f0f0f0;
  background-color: white;
  color: #3b3a36;
}
